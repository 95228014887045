export const IDENTITY_TYPE = {
  GENERAL: 0, // 普通的
  SALE: 1, // 销售
};

export const SocketStatusEnum = {
  OPEN: 'OPEN',
  CONNECTING: 'CONNECTING',
  CLOSED: 'CLOSED',
};

export const LocalStatusEnum = {
  SENDING: 1, // 正在发送
  ERROR: 2, // 发送失败
};

export const SocketTypeEnum = {
  ERROR: 'error',
  WARNING: 'warning',
  MSG_LIST: 'msgList',
  SEND_MSG: 'sendMsg',
  SEND: 'send',
  BIND: 'bind', // 绑定聊天室（这个项目好像用不到）
  MSG_NO_READ_NUM: 'msgNoReadNum', // 获取未读数
  CLICK: 'click', // 点击事件
  SEND_MSG_LIMIT: 'sendMsgLimit', // 次数上限
  ROOM_INFO: 'roomInfo',
  HIGH_SERVICE_INFO: 'highServiceInfo',
  READ_MSG: 'readMsg',
  PHRASE_LIST: 'phraseList', // 快速回复
  SET_PHRASE: 'setPhrase', // 编辑快速回复
  DEL_PHRASE: 'delPhrase', // 删除快速回复
  VOICE_COMPLETE: 'voiceComplete',
  IS_TO_USER_ONLINE: 'isToUserOnline',
  WITHDRAW_MSG: 'withdrawMsg', // 撤回消息
  DEL_MSG: 'delMsg',
  USER_USABLE_COUPON: 'userUsableCoupon', // 用户可用的优惠券
  USER_VIP_PRIVILEGES: 'userVipPrivileges', // 用户权益（会员权益）
};

/**
 * 全局socket的类型
 */
export const GlobalSocketTypeEnum = {
  MSG_NO_READ_NUM: SocketTypeEnum.MSG_NO_READ_NUM,
  SALE_NEW_MSG: 'Sale_NewMsg', // 新消息内容数据
  SALE_CHAT_READ_MSG: 'Sale_Chat_ReadMsg', // 咨询室用户消息已读
  SALE_USER_NICKNAME_REMARK: 'Sale_UserNickname_Remark', // 用户昵称备注
  SALE_SC_ORDER_NOTIFICATION: 'Sale_Sc_Order_Notification', //闪测订单提醒
};

export const MsgTypeEnum = {
  TEXT: 1, // 文本
  IMG: 2, // 图片
  MEDIA: 3, // 媒体消息，通常是音频
  REWARD_AMOUNT: 5, // 打赏金额
  RECOMMEND_SERVICE: 6, // 推荐服务
  PURCHASED_SERVICE: 7, // 购买服务
  TEACHER_PROFILE: 8, // 老师简介
  COUPON: 10, // 优惠券
  CALL_RESULT: 13, // 连线结果
  LINK_TIPS: 14, // 带链接的提示消息
  PREMIUM_SERVICE_ENDS: 20, // 高级咨询室服务结束
  AUTOMATIC_RESPONSE: 26, // 自动回复
  PAGE_JUMP_TIPS: 27, // 带页面跳转的提示消息
  SYSTEM_SERVICE_RECOMMEND: 29, // 系统推荐老师服务
  TAROT_TOOL_CARD: 32, // 塔罗工具卡片
  REPORT: 36, // 查看报告
  INVITE_CALL: 37, // 邀请通话
  SHAN_CE_ORDER: 39, // 闪测下单成功卡片
  INVITE_TIPPING: 41, // 邀请打赏卡片
  QUESTION_SELECT: 42, // 问题选择
  DISPATCH_INFORMATION: 44, // 派单信息（老师助理角色）
  RECOMMENDED_TEACHER: 45, // 推荐老师（老师助理角色）
  GIFT_GIVING: 46, // 赠礼卡片（老师助理角色）
  RECOMMENDED_USERS: 47, // 推荐用户（老师助理角色）
  INVITE_EVALUATION: 48, // 邀请评价（老师助理角色）
  SERVICE_EVALUATION: 49, // 服务评价（老师助理角色）
  ASSISTANT_COUPON: 50, // 优惠券（老师助理角色）
  NEWCOMER_BENEFITS: 51, // 新人福利（老师助理角色）
};
